.main-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 1rem;
  background-color: var(--background);
  min-height: calc(100vh - 55px);
}

.info-container {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 1rem;
  > * {
    flex: 1;
  }
  img {
    max-width: 256px;
    min-width: 200px;
    object-fit: contain;
  }
}

h1 {
  color: var(--text);
  background-color: var(--blue);
  margin: 0 -1rem;
  padding: 1rem;
}

.emoji {
  height: 1em;
  width: 1em;
}

.cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.cards.games {
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  gap: 0.5rem;
}

a:has(.card) {
  text-decoration: none;
  color: var(--text);
}

.card {
  border: 2px var(--border-color) solid;
  background-color: var(--grey);
  border-radius: 0.5rem;
  padding: 1rem;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  user-select: none;
  min-height: 64px;
  .emoji {
    align-self: center;
    height: 80px;
    width: 80px;
  }
  .logo {
    min-height: 86px;
    object-fit: contain;
    max-height: 110px;
  }
  &.true {
    border-color: var(--blue);
  }

  &.true::after {
    background-color: var(--blue) !important;
  }
  p{
    flex: 1;
  }

  &.game {
    padding: 0.9rem;
    flex-direction: row;
    p {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      font-weight: 600;
    }
    img {
      max-width: 64px;
    }
    &::after {
      content: attr(aria-details) "Gb";
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: var(--border-color);
      font-size: 0.9rem;
      color: var(--text);
      padding: 4px;
      border-radius: 6px 0 0 0;
    }
  }

  &.new::before {
    content: "Nuevo";
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem;
    background-color: var(--red);
    border-radius: 0.25rem;
  }
}

.console-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  img {
    max-height: 56px;
    max-width: 147px;
    object-fit: contain;
  }
  .data {
    display: flex;
    align-items: center;
  }
}

input[type="search"],
select {
  outline: none;
  border-radius: 8px;
  border: 1px var(--border-color) solid;
  padding: 0.5rem;
  margin: 0 0.5rem;
  flex: 1;
  max-width: 500px;
}

.selected-games {
  display: none;
  position: sticky;
  bottom: 2rem;
  margin: 0 0.25rem;
  background-color: #222;
  border: 2px var(--border-color) solid;
  padding: 1rem;
  padding-top: 0.5rem;
  z-index: 2;
  border-radius: 1rem;
  flex-direction: column;
  &.true {
    display: flex;
    ~ button {
      bottom: calc(2rem + 2rem + 38px + 6px + 1rem);
    }
  }

  .games {
    display: grid;
    list-style: none;
    overflow: hidden;
    max-height: 0;
    transition: max-height 250ms ease;

    &.true {
      max-height: 300px;
      overflow: auto;
      ~ div {
        border-top: 1px var(--border-color) solid;
      }
    }
  }
  .main-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5rem;
    border-top: 1px transparent solid;
    .data > p:first-child {
      font-weight: 600;
    }

    .actions {
      display: flex;
      gap: 0.5rem;
    }
  }
}

button,
a.action-button {
  border: none;
  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
}

button.icon-button {
  background-color: transparent;
  outline: none;
  border: none;
  transition: filter 100ms ease-in, rotate 150ms ease-in;
  &.arrow {
    rotate: 270deg;

    &.true {
      rotate: 90deg;
    }
  }
  > img {
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    height: 32px;
    width: 32px;
  }
}

a.action-button {
  text-decoration: none;
  color: var(--text);
  text-align: center;
  background-color: var(--blue);
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
}


.panel-item {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  > img {
    height: 56px;
    width: 56px;
    border-radius: 50%;
  }
  > span {
    font-weight: 600;
  }
}

.scroll-button {
  position: fixed;
  right: 1rem;
  bottom: 40px;
  height: 40px;
  width: 40px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  background-color: #222;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  > img {
    height: 28px;
    width: 28px;
    rotate: 270deg;
  }
}

.shimmer {
  background: linear-gradient(
    to right,
    var(--background) 35%,
    var(--blue) 50%,
    var(--background) 70%
  );
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}

section > h2 {
  padding-bottom: 0.25rem;
  border-bottom: 2px var(--text) solid;
  margin-bottom: 0.5rem;
}
