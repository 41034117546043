@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&display=swap');
body {
  margin: 0;
  font-family: "Nanum Gothic", "Gotham", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/background.jpg');
  background-position: top;
  background-attachment: fixed;
  color: #d8d4cf;
  height: 100vh;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue: #0c6fbf;
  --red: #af001a;
  --text-red: #ff3b58;
  --hover-background: #33333355;
  --grey: #28292d8f;
  --background: #1c2326ba;
  --border-color: #2e3437;
  --text: #d8d4cf;
  color-scheme: dark;
}
