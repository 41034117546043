footer {
  backdrop-filter: blur(5px);
  background-color: var(--grey);
  padding: 1rem;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  a,
  label {
    display: block;
  }

  a{
    margin-bottom: 1rem;
    text-decoration: none;
    color: var(--text-red);
  }

  address{
    margin: -1rem;
    margin-right: 0;
    background-color: #367db732;
    padding: 1rem;
  }

  .social {
    display: flex;
    gap: 1rem;
    position: relative;
    padding-top: 1rem;
    padding-right: 1rem;
    &::before{
        content: attr(aria-label);
        position: absolute;
        top: -2px;
    }
  }
}
