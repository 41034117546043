.sk-spinner {
  color: var(--blue);
}

.sk-fade-in {
  animation: sk-loader 2s;
}

.sk-cube-grid {
  width: 64px;
  height: 64px;
}
.sk-cube-grid > div:first-child {
  animation-delay: 0.2s;
}

.sk-cube-grid>div:nth-child(2) {
    animation-delay: .3s;
}

.sk-cube-grid > div {
  background-color: currentcolor;
}

.sk-cube-grid>div {
    width: 33%;
    height: 33%;
    background-color: currentColor;
    float: left;
    animation: sk-scaleDelay 1.3s infinite ease-in-out;
}

@keyframes sk-loader {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sk-scaleDelay {
    0%, 70%, 100%{
        transform: scale3D(1, 1, 1);
    }
    35% {
        transform: scale3D(0, 0, 1);
    }
}
