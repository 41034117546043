header {
  position: sticky;
  top: 0;
  backdrop-filter: blur(5px);
  background-color: var(--grey);
  padding: 0 1rem;
  z-index: 2;
  .logo {
    height: 64px;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;
  }
}

nav {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 0.5rem 0;
  > ul {
    display: flex;
  }
}

.hamburguer {
  display: none;
}

.nav-item {
  font-size: 1.25rem;
  list-style: none;
  position: relative;
  color: var(--text-red);
  transition: background 250ms ease-in, color 250ms ease-in;
  border-radius: 8px;
  &:has(.submenu) {
    border-radius: 8px 8px 0 0;
  }
  &:hover {
    background-color: var(--red);
    color: var(--text);

    .submenu {
      display: flex;
      scale: 1;
    }
  }
  span,
  a {
    height: 100%;
    display: flex;
    padding: 0.5rem;
    text-decoration: none;
    color: inherit;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }
}
.submenu {
  flex-direction: column;
  position: absolute;
  top: 2.25rem;
  left: 0;
  color: var(--text);
  background-color: var(--red);
  scale: 1 0;
  transition: scale 250ms ease-in;
  transform-origin: top;
  border-radius: 0 0 8px 8px;
  > li {
    counter-increment: submenu;
  }

  > .nav-item {
    color: var(--text);
    &:hover {
      color: var(--red);
      background-color: var(--text);
    }
  }
}

@media screen and (max-width: 472px) {
  .hamburguer {
    display: block;
  }

  .nav-items {
    position: fixed;
    top: 50px;
    right: 0;
    width: 0;
    bottom: 0;
    height: calc(100vh - 50px);
    flex-direction: column;
    background-color: #222;
    align-items: flex-end;
    backdrop-filter: blur(95px);
    z-index: 1;
    transition: width 250ms ease-in;
    transform-origin: right;
    overflow-x: hidden;
    &.true {
      width: 100%;
    }
    .nav-item {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-wrap: nowrap;
      &:has(.submenu) {
        color: var(--text);

        &:hover {
          background-color: transparent;
        }
      }
    }
    .submenu {
      position: static;
      scale: 1;
      color: var(--text-red);
      background-color: transparent;
      .nav-item {
        color: inherit;
      }
    }
  }
}
